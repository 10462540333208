*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.App {
  height: 100vh;
  width: 100vw;
  background-image: url("./images/Background_Startseite.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.lego-container{
  position: absolute;
  height: 30%;
  top:41%;
  left:50%;
  transform: translate(-50%,-50%);
}

.start-page-buttons{
  position: absolute;
  display: flex;
  flex-direction: column;
  top:75%;
  left:50%;
  width: 60%;
  transform: translate(-50%,-50%);
}

.information{
  z-index: -10;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 17%;
  background-color: #fff;
}

.setting-page{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: space-around;

}
.Scan{
  z-index: -10;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header{
  width: 100%;
  height: 5%;
  background-color: #fff;
  opacity: 0.7;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.header-style{
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin: 10px;
}

.header_button{
  z-index: 1000;
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin: 10px;
}

.Model{
  overflow: hidden;
  width: 100%;
  height: 250px;
  border-radius: 9px;
}

.details {
  margin-top: 20px;
  flex: 3;
  display: block;
  width: 155%;
  overflow-y: scroll;
}

.details-row {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.details-row-left{
  width: 40%;
  margin-right: 10px;
  text-align: right;
}

.details-row-right{
  width: 40%;
  text-align: left;
}

.label-title{
  font-family: Roboto;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
